import { computed } from 'vue';
import { LANDING_PAGES as HUBSPOT_LANDING_PAGES } from '@base/constants/hubspot';
import { sentry } from '@/plugins/sentry';
import { useAccountInfo } from '@base/hooks/useAccountInfo';

/**
 * Composable for HubSpot landing pages usage
 * @see {@link https://public.3.basecamp.com/p/XSix19Ag5mUgh1EpnqyyVanv Lista de landing pages}
 * @param {object}
 * @example
 * // with controls
 * const { links, buildHubspotLandingPageUrl } = useLandingPages({ withControls: true });
 * // without controls
 * const landingPages = useLandingPages();
 * */
export function useLandingPages(options = { withControls: false }) {
  const { account } = useAccountInfo();

  /**
   * Builds hubspot landing page URLs with account and user params
   * @param {string} URL - Base landing page URL
   * @returns {string} Landing page URL with the added query params
   * @example
   * // returns page url with added params
   * // url?firstname=foo&account=bar&email=foo@bar.com
   * buildHubspotLandingPageUrl(LANDING_PAGES.UPGRADE_API_INTEGRATIONS);
   * */
  function buildHubspotLandingPageUrl(url) {
    try {
      const formParams = {
        email: 'email',
        firstName: 'firstname',
        lastName: 'lastname',
        accountName: 'account_name',
        accountId: 'account_id_',
      };

      const userName = account.value.user.name.split(' ');

      const formParamsValues = {
        [formParams.email]: account.value.user.email,
        [formParams.firstName]: userName[0],
        [formParams.lastName]: userName[userName.length - 1],
        [formParams.accountName]: account.value.name,
        [formParams.accountId]: account.value.id,
        content: `Account ID: ${account.value.id}`,
      };

      const parsedUrl = new URL(url);
      Object.keys(formParamsValues).forEach((key) => {
        parsedUrl.searchParams.append(key, formParamsValues[key]);
      });
      return parsedUrl.toString();
    } catch (error) {
      sentry.captureException(error);
      return url;
    }
  }

  const links = computed(() =>
    Object.keys(HUBSPOT_LANDING_PAGES).reduce((linksObject, landingPageKey) => {
      const link = HUBSPOT_LANDING_PAGES[landingPageKey];

      Object.assign(linksObject, { [landingPageKey]: buildHubspotLandingPageUrl(link) });
      return linksObject;
    }, {})
  );

  if (options.withControls) return { buildHubspotLandingPageUrl, links };

  return links;
}
